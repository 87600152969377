<template>

	<div>
		<user-list-add-new
			v-if="roleOptions"
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:role-options="roleOptions"
			:status-options="statusOptions"
			:country-lists="countryLists"
			@refetch-data="refetchData"
		/>
		<!-- Filters -->
		<users-list-filters
			:username-filter.sync="usernameFilter"
			:email-filter.sync="emailFilter"
			:role-filter.sync="roleFilter"
			:role-options="roleOptions"
			:department-filter.sync="departmentFilter"
			:department-options="departmentOptions"
			:status-filter.sync="statusFilter"
			:status-options="statusOptions"
			:handle-search.sync="refetchData"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div
				v-if="$can('create','staff')"
				class="m-2"
			>

				<!-- Table Top -->
				<b-row>
					<!-- Search -->
					<b-col
						cols="12"
					>
						<div class="d-flex justify-content-end">
							<b-button
								variant="primary"
								@click="isAddNewUserSidebarActive = true"
							>
								<span class="text-nowrap">
									<feather-icon
										icon="UserPlusIcon"
									/>
									{{ $t("Add User") }}
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<b-table
				ref="refUserListTable"
				class="position-relative table-white-space max-height-table"
				sticky-header
				head-variant="light"
				:no-border-collapse="true"
				:items="fetchUsers"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template  #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>
				<!-- Column: User -->
				<template #cell(user)="data">
					<b-link class="cursor-pointer text-primary" @click="showModalEditInfo(data.item.id)">
						{{ data.item.username }}
					</b-link>
				</template>

				<template #cell(department)="data">
					{{ data.item.roles.map(item => item.department.name).toString() }}
				</template>

				<template #cell(roles)="data">
					{{ data.item.roles ? data.item.roles.map(item => item.name).toString() : '' }}
				</template>

				<template #cell(createdBy)="data">
					{{ data.item.created_by }}
				</template>
				

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
				</template>

				<template #cell(updatedAt)="data">
					{{ formatDateTime(data.item.updated_at).replace('.000000Z', '') }}
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<div
						v-if="$can('view','staffedit')"
						:class="`text-capitalize align-items-center cursor-pointer d-flex text-${resolveUserStatus(data.item.status).variant}`"
						@click="showModalUpdateStatus(data.item.id)"
					>
						<span :class="`dot bg-${resolveUserStatus(data.item.status).variant}`" />{{ resolveUserStatus(data.item.status).label }}
					</div>
					<div
						v-else
						:class="`text-capitalize align-items-center d-flex text-${resolveUserStatus(data.item.status).variant}`"
					>
						<span :class="`dot bg-${resolveUserStatus(data.item.status).variant}`" />{{ resolveUserStatus(data.item.status).label }}
					</div>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<!-- Dropdown -->
						<b-dropdown
							variant="link"
							toggle-class="p-0"
							no-caret
							:right="$store.state.appConfig.isRTL"
						>

							<template #button-content>
								<feather-icon
									icon="MoreHorizontalIcon"
									size="16"
									class="align-middle text-body"
								/>
							</template>
							<b-dropdown-item
								v-if="$can('edit','staff')"
								@click="showModalEditInfo(data.item.id)"
							>
								<feather-icon icon="EditIcon" />
								<span class="align-middle ml-50">{{ $t('Edit') }}</span>
							</b-dropdown-item>
							<b-dropdown-item
								v-if="$can('read','staffresetpassword')"
								@click="showModalUpdatePassword(data.item.id)"
							>
								<feather-icon icon="RefreshCwIcon" />
								<span class="align-middle ml-50">{{ $t('Reset Password') }}</span>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalUsers"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Model Change User Password -->
		<user-change-password-modal
			:user-data="userData"
		/>
		<user-change-status-modal
			:staff-id.sync="staffId"
			:status-options="statusOptions"
			@refetch-data="refetchData"
		/>
		<!-- Modal Edit User Info -->
		<user-edit-modal
			:staff-id.sync="staffId"
			:user-data="userData"
			:role-options="roleOptions"
			:status-options="statusOptions"
			:country-lists="countryLists"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, defineComponent } from '@vue/composition-api'
import { avatarText, formatDateTime, formatRemoveTime, numberFormat } from '@core/utils/filter'
import store from '@/store'
import roleStoreModule from '@/views/local-setting/local/roleStoreModule'
import UserChangePasswordModal from './UserChangePasswordModal.vue'
import UserChangeStatusModal from './UserChangeStatusModal.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '@/views/apps/user/userStoreModule'
import memberStoreModule from '@/views/apps/member/memberStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import CountryService from '@/libs/country.service'
import i18n from '@/libs/i18n'

export default {
	components: {
		UsersListFilters,
		UserListAddNew,
		UserEditModal: () => import('@/views/apps/member/members-list/UserEditModal.vue'),
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,

		vSelect,

		UserChangePasswordModal,
		UserChangeStatusModal,
	},
	data () {
		return {
			staffId: null,
			userData: {
				username: '',
				id: '',
				status: null,
				fullName: null,
				email: null,
				roleId: null,
			},
		}
	},
	methods: {
		showModalUpdatePassword(id) {
			store.dispatch('app-member/fetchUserDetail', id)
				.then(response => { 
					this.userData.id = response.data.data.id
					this.userData.username = response.data.data.username

				})
			this.$bvModal.show('modal-update-password')
		},
		showModalUpdateStatus(id) {
			this.staffId = id
			this.$bvModal.show('modal-update-status')
		},
		showModalEditInfo(id) {
			this.staffId = id
			this.$bvModal.show('modal-edit-user')
		}
	},
	setup() {
		const departmentOptions = ref([])

		const USER_APP_STORE_MODULE_NAME = 'app-user'
		const LOCAL_SETTING_ROLE_STORE_MODULE_NAME = 'local-setting-role'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
		if (!store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME)) store.registerModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME, roleStoreModule)
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			// if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
			if (store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME)) store.unregisterModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME)
		})

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = ref([])
		const countryLists = ref([])

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatus,
			resolveUserEmailVerify,

			// Extra Filters
			usernameFilter,
			emailFilter,
			roleFilter,
			departmentFilter,
			statusFilter,
			statusUpdate,
		} = useUsersList()

		store.dispatch('local-setting-role/fetchRoles')
			.then(response => {
				roleOptions.value = response.data
			})

		store
			.dispatch('app-member/fetchDepartments', {
				perPage: perPage.value + 100,
				page: currentPage.value,
				name: null,
			})
			.then(response => {
				const { departments } = response
				departmentOptions.value = departments
			})

		const statusOptions = [
			{ label: i18n.t('Active'), value: 1 },
			{ label: i18n.t('Inactive'), value: 2 },
		]

		CountryService.getCountryLists()
			.then(response => {
				countryLists.value = response.data.data
			})

		const sumBy = items => items.reduce((n, { netAmount }) => n + netAmount, 0)

		return {

			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// Filter
			avatarText,
			formatDateTime,
			formatRemoveTime,
			numberFormat,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatus,
			resolveUserEmailVerify,
			sumBy,

			roleOptions,
			departmentOptions,
			statusOptions,

			// Extra Filters
			usernameFilter,
			emailFilter,
			roleFilter,
			departmentFilter,
			statusFilter,
			statusUpdate,

			countryLists,
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>