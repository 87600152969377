import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useUsersList(app) {
	// Use toast
	const toast = useToast()

	const refUserListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'user', label: i18n.t('Login Name'), stickyColumn: true },
		{ key: 'full_name', label: i18n.t('Full Name') },
		{ key: 'email' },
		{ key: 'department', label: i18n.t('Department') },
		{ key: 'roles', label: i18n.t('Roles') },
		{ key: 'status', label: i18n.t('Status') },
		{ key: 'call_center_extension', label: i18n.t('callCenterExtension') },
		{ key: 'createdBy', label: i18n.t('Created By') },
		{ key: 'createdAt', label: i18n.t('Created at') },
		{ key: 'updatedAt', label: i18n.t('Updated at') },
		{ key: 'actions', label: i18n.t('Actions') },
	]
	const perPage = ref(25)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const usernameFilter = ref(null)
	const emailFilter = ref(null)
	const roleFilter = ref(null)
	const departmentFilter = ref(null)
	const statusFilter = ref(null)
	const statusUpdate = ref(false)

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		}
	})

	const refetchData = () => {
		refUserListTable.value.refresh()
	}

	watch([currentPage], () => {
		refetchData()
	})

	const fetchUsers = (ctx, callback) => {
		store
			.dispatch('app-member/fetchUsers', {
				perPage: perPage.value,
				page: currentPage.value,
				username: usernameFilter.value,
				email: emailFilter.value,
				roleId: roleFilter.value,
				departmentId: departmentFilter.value,
				status: statusFilter.value,
			})
			.then(response => {
				if (response.code == '00') {
					const { users, total, pageSize } = response

					callback(users)
					totalUsers.value = total
					perPage.value = pageSize
				} else {
					toast({
						component: ToastificationContent,
						props: {
							title: response.message || 'Error fetching users list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				}
			})
			.catch(error => {
				const { response } = error
				toast({
					component: ToastificationContent,
					props: {
						title: response.data.message || 'Error fetching users list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserRoleVariant = role => {
		if (role === 'subscriber') return 'primary'
		if (role === 'author') return 'warning'
		if (role === 'maintainer') return 'success'
		if (role === 'editor') return 'info'
		if (role === 'admin') return 'danger'
		return 'primary'
	}

	const resolveUserRoleIcon = role => {
		if (role === 'subscriber') return 'UserIcon'
		if (role === 'author') return 'SettingsIcon'
		if (role === 'maintainer') return 'DatabaseIcon'
		if (role === 'editor') return 'Edit2Icon'
		if (role === 'admin') return 'ServerIcon'
		return 'UserIcon'
	}

	const resolveUserStatus = stat => {
		if (stat === 1) return { label: i18n.t('Active'), variant: 'success' }
		if (stat === 2) return { label: i18n.t('Inactive'), variant: 'danger' }
		return { label: 'None', variant: 'secondary' }
	}

	const resolveUserEmailVerify = verify => {
		if (verify == '0') return {label: 'UnVerified', icon: 'XCircleIcon', variant: 'danger' }
		return {label: 'Verified', icon: 'CheckCircleIcon', variant: 'success' }
	}

	return {
		fetchUsers,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatus,
		resolveUserEmailVerify,
		refetchData,

		// Extra Filters
		usernameFilter,
		emailFilter,
		roleFilter,
		departmentFilter,
		statusFilter,
		statusUpdate,
	}
}
