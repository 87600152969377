<template>
	<b-card no-body>
		<b-card-body>
			<b-row style="margin:10px 0">
				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label>{{ $t('Login Name') }}</label>
					<b-form-input
						v-model="filter.username"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
					/>
				</b-col>
				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label>Email</label>
					<b-form-input
						v-model="filter.email"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
					/>
				</b-col>
				<b-col
					cols="12"
					md="3"
					class="mb-md-0 mb-2"
					hidden
				>
					<label>{{ $t('Department') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="departmentFilter"
						:options="departmentOptions"
						class="w-100"
						:reduce="val => val.id"
						label="name"
						@input="(val) => $emit('update:departmentFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="3"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Role') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="filter.roleFilter"
						:options="roleOptions"
						class="w-100"
						:reduce="val => val.id"
						label="name"
						@input="(val) => $emit('update:roleFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="3"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Status') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="filter.statusFilter"
						:options="statusOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:statusFilter', val)"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
					style="margin-top: 10px"
				>
					<b-button
						variant="outline-secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		BButton,
		vSelect,
	},
	props: {
		usernameFilter: {
			type: [String, null],
			default: null,
		},
		emailFilter: {
			type: [String, null],
			default: null,
		},
		departmentFilter: {
			type: [Number, null],
			default: null,
		},
		roleFilter: {
			type: [Number, null],
			default: null,
		},
		statusFilter: {
			type: [Number, null],
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
		},
		roleOptions: {
			type: Array,
			required: true,
			default:null,
		},
		departmentOptions: {
			type: Array,
			required: true,
			default:null,
		},
		handleSearch: {
			type: [Function, null]
		}
	},
	data() {
		return {
			filter: {
				username: this.usernameFilter,
				email: this.emailFilter,
				roleFilter: this.roleFilter,
				statusFilter: this.statusFilter,
			},
		}
	},
	methods: {
		resetFilter() {
			this.$emit('update:usernameFilter', null)
			this.$emit('update:emailFilter', null)
			this.$emit('update:departmentFilter', null)
			this.$emit('update:roleFilter', null)
			this.$emit('update:statusFilter', null)
			this.filter = {
				username: null,
				email: null,
				departmentFilter: null,
				roleFilter: null,
				statusFilter: null,
			}
		},
		searchFilter() {
			this.$emit('update:usernameFilter', this.filter.username)
			this.$emit('update:emailFilter', this.filter.email)
			this.$emit('update:departmentFilter', this.filter.departmentFilter)
			this.$emit('update:roleFilter', this.filter.roleFilter)
			this.$emit('update:statusFilter', this.filter.statusFilter)
			this.handleSearch();
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
