<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ $t("Add new user") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group :label="$t('Full Name')" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|alpha_dash|min:3|max:20"
          >
            <b-form-group :label="$t('Username')" label-for="username">
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider #default="validationContext" name="Phone">
            <b-form-group :label="$t('Phone')" label-for="phone" hidden>
              <b-form-input
                id="phone"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <b-form-group :label="$t('Password')" label-for="password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required|min:6|max:30"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="user-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="
                      passwordFieldType === 'password'
                        ? 'EyeIcon'
                        : 'EyeOffIcon'
                    "
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              :label="$t('User role')"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.roleId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                label="name"
                input-id="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Market -->
          <validation-provider
            #default="validationContext"
            name="market"
            rules="required"
          >
            <b-form-group
              :label="$t('Market')"
              label-for="market"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.marketId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryLists"
                :reduce="(val) => val.id"
                :clearable="false"
                label="name"
                input-id="market"
                disabled
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- callCenterExtension -->
          <validation-provider
            #default="validationContext"
            name="callCenterExtension"
          >
            <!-- rules="required|email" -->
            <b-form-group
              :label="$t('callCenterExtension')"
              label-for="callCenterExtension"
            >
              <b-form-input
                id="callCenterExtension"
                v-model="userData.call_center_extension"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ipWhiteList"
          >
            <!-- rules="required|email" -->
            <b-form-group
              :label="$t('Ip Whitelist')"
              label-for="ipWhiteList"
            >
              <b-form-input
                id="ipWhiteList"
                v-model="userData.ip"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-provider
            #default="validationContext"
            name="callCenterExtension"
          >
            <b-form-group
              :label="$t('callCenterExtension')"
              label-for="callCenterExtension"
            >
              <b-form-input
                id="callCenterExtension"
                v-model="userData.call_center_extension"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider #default="validationContext" name="Birthday">
            <b-form-group
              label="Birthday"
              label-for="birthday"
              :state="getValidationState(validationContext)"
              hidden
            >
              <b-input-group>
                <b-form-input
                  v-model="userData.birthday"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="userData.birthday"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="en-US"
                    @context="onContextBirthday"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormDatepicker,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormDatepicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      default: null,
    },
    countryLists: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  watch: {
    "userData.username": {
      handler(val) {
        this.userData.username = val.replace(/\s/, "").toLowerCase();
      },
      deep: true,
    },
    "userData.password": {
      handler(newVal) {
        this.userData.password = newVal.replace(/\s/, "");
      },
      deep: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      roleId: "",
      password: "",
      call_center_extension: "",
      ip:"",
      // phone: `0896690${Math.floor(Math.random() * 1001)}`,
      // birthday: '1990-01-01',
      marketId: 1,
    };
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store
        .dispatch("app-member/addUser", userData.value)
        .then((response) => {
          console.log(123, userData.value);
          if (response.status === 200) {
            if (response.data.code == "00") {
              emit("refetch-data");
              emit("update:is-add-new-user-sidebar-active", false);
              blankUserData.phone = `0896690${Math.floor(Math.random() * 101)}${
                response.data.id
              }`;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        })
        .catch((error) => {
          const { response } = error;
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const onContextBirthday = (ctx) => {
      emit("update:userData.birthday", ctx.selectedYMD);
    };

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      onContextBirthday,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
