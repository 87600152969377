/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class CountryService {

	getCountryLists() {
		return instance.get('/api/country/index', { headers: authHeader() })
	}
}

export default new CountryService()
