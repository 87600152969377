<template>
	<div>
		<b-modal
			id="modal-update-status"
			ref="updateUserStatus"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			centered
			:title="$t('Change status')"
			@ok="confirmUpdateStatus"
		>
			<!-- form -->
			<validation-observer ref="changeUserStatus">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormStatus"
				>

					<!-- Field: Username -->
					<b-form-group
						:label="$t('Username')"
						label-for="username"
					>
						<b-form-input
							id="usernameStatus"
							v-model="uData.username"
							disabled
						/>
					</b-form-group>
					<!-- Field: Status -->
					<b-form-group
						:label="$t('Status')"
						label-for="user-status"
					>
						<v-select
							v-model="uData.status"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="statusOptions"
							:reduce="val => val.value"
							:clearable="false"
							input-id="user-status"
						/>
					</b-form-group>

					<!-- Field: Status -->
					<b-form-group
						label="Note"
						label-for="user-status-note"
						hidden
					>
						<validation-provider
							#default="{ errors }"
							name="Note"
						>
							<b-form-textarea
								id="user-status-note"
								v-model="note"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:class="errors.length > 0 ? 'is-invalid':null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatRemoveTime } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		staffId: {
			type: Number,
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			uData: {
				username: '',
				fullName: '',
				roleId: '',
				marketId: 1,
				email: '',
				status: '',
				staffId: null,
				// phone: '',
				// birthday: '',
			},
			note: ''
		}
	},
	watch: {
		staffId: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.getUserDetail(newVal)
				}
			}
		}
	},
	methods: {
		validationFormStatus() {
			this.$refs.changeUserStatus.validate().then(success => {
				if (success) {
					store.dispatch('app-member/updateUser', this.uData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code != '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message ? response.data.message : 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$emit('refetch-data')
								}
								this.$nextTick(() => {
									setTimeout(() => {
										this.$bvModal.hide('modal-update-status')
									}, '500')
									setTimeout(() => {
										this.resetModal()
									}, '1000')
								})
							}
						})
						.catch(error => {
							const { config, response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		getUserDetail(id) {
			store.dispatch('app-member/fetchUserDetail', id)
				.then(response => {
					this.uData.staffId = id
					this.uData.username = response.data.data.username
					this.uData.fullName = response.data.data.full_name
					this.uData.status = response.data.data.status
					this.uData.email = response.data.data.email
					this.uData.marketId = response.data.data.market_id
					this.uData.roleId = parseInt(response.data.data.roles.map(item => item.id).toString())
					// this.uData.phone = response.data.data.phone
					// this.uData.birthday = response.data.data.dob
				})
		},
		resetModal() {
			this.$emit('update:staffId', null)
			this.uData = {
				username: '',
				fullName: '',
				roleId: '',
				marketId: 1,
				email: '',
				status: '',
				staffId: null,
				phone: '',
				birthday: '',
			}
			this.note = ''
		},
		confirmUpdateStatus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormStatus()
		}
	},
	setup() {

		return {
		}
	},
}
</script>

<style>

</style>
